<template>
  <div>
    This needs to be fixed. Pull notification types.
    <ion-item v-for="item in storedSettings" :key="item">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-label>{{item.name}}</ion-label>  
      <ion-toggle
        slot="start"
        :value="item.id"
        @click="updateItem(item.id)"
        :checked="isChecked(item.id)">
      </ion-toggle>    
    </ion-item> 
  </div>
</template>

<script>
import { IonItem, IonLabel, IonToggle, loadingController, toastController } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
//import { person } from 'ionicons/icons';
import apiClient from '../services/api';

export default defineComponent({
  name: 'ProfileNotifications', 
  props: ['store'],
  components: {
   IonItem, IonLabel, IonToggle
  },
  setup(props) {
    const userSettings = ref(null); 
    const storedSettings = ref(null);
    const checkedSettings = ref([]);
    const timeout = { default: 1000 }

    onMounted(() => {
      if(props.store.authUser.settings) {
        userSettings.value = props.store.authUser.settings
      }
      getAppSettings()
      addToCheckedSettings()
    })

    async function presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',
          duration: timeout,
        });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
        .create({
          //message: '',
          duration: 2000,
          message: 'Your profile has been updated.',
          position: 'bottom',
          buttons: [
            {
              icon: 'close',
              role: 'cancel',
              handler: () => {
                
              }
            }
          ]
        })
      return toast.present();
    }

    function getAppSettings() {
      presentLoading()
      apiClient.get('/api/general-notification-settings', {
          headers: {
            'Authorization':  `Bearer ${props.store.authToken}`
          }
        }).then(response => {
          console.log(response)
          storedSettings.value = response.data
        }).catch(error => {
          console.log(error)  
        });      
    }

    // Is this check box item checked?
    function isChecked(id) {
      var settingValue = false
      for(var i=0;i<userSettings.value.length;i++) {
        if(userSettings.value[i].setting_id === id) {
          if(userSettings.value[i].value === 1) {
            settingValue = true
          }
        } 
      }
      return settingValue
    }

    // Add user genres to checked genres
    function addToCheckedSettings() {
      for(var i=0;i<userSettings.value.length;i++) {
        checkedSettings.value.push({
          setting_id: userSettings.value[i].setting_id,
          value: userSettings.value[i].value
        }) 
      }      
    }

    function updateItem(id) {
      
      for(var i = 0; i<checkedSettings.value.length;i++) {
        if(checkedSettings.value[i].setting_id == id) {

          if(checkedSettings.value[i].value == 1) {
            checkedSettings.value[i].value = 0
          } else {
            checkedSettings.value[i].value = 1
          }

          // update db and store
          updateUserSetting(checkedSettings.value[i].setting_id, checkedSettings.value[i].value)
        }
      }
    }

    function updateUserSetting(settingId, settingValue) {
      presentLoading()
      apiClient.post('/api/update/user-setting', 
        {
          setting_id: settingId,
          value: settingValue           
        },
        {
          headers: {
            'Authorization':  `Bearer ${props.store.authToken}`
          }
        }).then(response => {
          console.log(response)
          localStorage.setItem('authUser', JSON.stringify(response.data.user));
          this.dataUp(response.data.user);
          openToast()
        }).catch(error => {
          console.log(error)  
          // credentials didn't match

          //this.isError = true
          //this.errors = error.response.data.errors

        });
    }

    return {
      userSettings, storedSettings, checkedSettings, getAppSettings, isChecked, updateItem, updateUserSetting, presentLoading 
    }
  },
  methods: {
    dataUp: function(user) {
      var componentData = {
        dataType: 'user',
        data: user
      }

      this.$emit('component-data', componentData)
    }
  } 
});
</script>